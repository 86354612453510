// Reviews.js

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import '../Reviews/Reviews.css';
import leftArrow from '../../images/left-arrow.svg';
import rightArrow from '../../images/right-arrow.svg';

export const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
            <img src={leftArrow} alt="Prev" className='img-fluid'/>
        </div>
    );
};

export const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
            <img src={rightArrow} alt="Next" className='img-fluid'/>
        </div>
    );
};

const Reviews = ({ reviews }) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const logoSliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        centerMode: true,
        centerPadding: 0,
        slidesToScroll: 1,
        asNavFor: nav2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        ref: slider => setNav1(slider),
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                }
            },
        ]
    };

    const detailSliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: nav1,
        fade: true,
        ref: slider => setNav2(slider),
    };

    return (
        <section className='reviews-wrp'>
            <Container>
                <div className='reviews-main'>
                    <div className='section-title'>
                        <h2>{reviews.maintitle}</h2>
                    </div>
                    <div className='reviews-main'>
                        <div className='reviews-logo-slider'>
                            <Slider {...logoSliderSettings}>
                                {reviews.reviewLogo.map((imgSrc, index) => (
                                    <div className='reviews-logo-item' key={index}>
                                        <img src={imgSrc} alt={`Logo ${index + 1}`} className='img-fluid' />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className='reviews-detail-slider'>
                            <Slider {...detailSliderSettings}>
                                {reviews.reviewDetails.map((review, index) => (
                                    <div className='reviews-detail-item' key={index}>
                                        <div className='small-title'>
                                            <h4>{review.title}</h4>
                                        </div>
                                        <div className='detail'>
                                            <p>{review.detail}</p>
                                        </div>
                                        <div className='reviews-detail-bottom'>
                                            <div className='reviews-detail-img'>
                                                <img src={review.img} alt={review.name} className='img-fluid' />
                                            </div>
                                            <div className='reviews-detail-content'>
                                                <h5>{review.name}</h5>
                                                <p>{review.position}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default Reviews;
