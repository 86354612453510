import React from 'react';
import Slider from "react-slick";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Banner/Banner.css';
import { Link as ScrollLink } from 'react-scroll';

const Banner = ({banner}) => {
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        variableWidth: false,
        autoplay: true,
		autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    variableWidth: false,
                }
            },
        ]
	};
    return (
        <>
            <div className='banner-wrp' style={banner.bgimg}>
                <Container>
                    <div className='banner-main'>
                        <div className='banner-top'>
                            <Row className='align-items-end'>
                                <Col lg={6} xl={7} xxl={7}>
                                    <div className='banner-left'>
                                        <span>{banner.bannertext}</span>
                                        <div className='banner-title'>
                                            <h1>{banner.bannertitle}</h1>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} xl={5} xxl={5}>
                                    <div className='banner-right'>
                                        <div className='common-detail'>
                                            <p>{banner.bannerdetail}</p>
                                        </div>
                                        <ScrollLink to="contact" smooth={true} duration={500} offset={-50} className='btn btn-gradient'>{banner.bannerbtn}</ScrollLink>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='banner-slider'>
                            <Slider {...settings}>
                                {banner.bannerItems.map((src, index) => (
                                    <div className='banner-item' key={index}>
                                        <img src={src} alt={banner.alt} className='img-fluid' />
                                    </div>
                                 ))}
                            </Slider>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Banner;