import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../SpeedScan/speedscan.css';
import { Link as ScrollLink } from 'react-scroll';

const Speedscan = ({ speedscan }) => {
    const { rowsData } = speedscan;

    return (
        <>
            <section className='speedscan-wrp'>
                {rowsData && rowsData.map((rowData, index) => (
                    <div className='speedscan-row' id={`speedscan-row-${index}`} key={index}> {}
                        <Container>
                            <div className='speedscan-section'>
                                <Row className='align-items-center'>
                                    <Col lg={6} xl={6} xxl={6}>
                                        <div className='speedscan-left'>
                                            <div className='speedscan-img'>
                                                <img src={rowData.img} alt={rowData.alt} className='img-fluid'/>
                                            </div>
                                            <div className='speedscan-count'>
                                                {rowData.countBlocks && rowData.countBlocks.map((countBlock, countIndex) => (
                                                    <div className='count-block' key={countIndex}>
                                                        <div className='block-title'>
                                                            <h2>{countBlock.countnumber}</h2>
                                                        </div>
                                                        <p>{countBlock.countdetail}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} xl={6} xxl={6}>
                                        <div className='speedscan-right'>
                                            {index === 0 && (
                                                <div className='coming-soon'>
                                                    <span>{rowData.comingsoon}</span>
                                                </div>
                                            )}
                                            <div className='section-title'>
                                                <h3>{rowData.maintitle}</h3>
                                            </div>
                                            <div className='common-detail'>
                                                <p>{rowData.maindetail}</p>
                                            </div>
                                            <div className='speedscan-content'>
                                                <Row>
                                                    {rowData.speedscanData.map((item, itemIndex) => (
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} key={itemIndex}>
                                                            <div className='speedscan-block'>
                                                                <div className='small-title'>
                                                                    <h4>{item.title}</h4>
                                                                </div>
                                                                <div className='detail'>
                                                                    <p>{item.detail}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                            <div className='speedscan-btn'>
                                                <ScrollLink to="contact" smooth={true} duration={500} offset={-50} className='btn btn-gradient'>{rowData.btnLink}</ScrollLink>
                                                <Link to='https://s3infosoft.com/' target="_blank" className='btn btn-text'>{rowData.btnText}</Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                ))}
            </section>
        </>
    );
}

export default Speedscan;
