import React from 'react';
import Banner from '../../components/Banner/Banner';
import { banner, reviews, speedscan, contactus } from '../../components/Data';
import Speedscan from '../../components/SpeedScan/Speedscan';
import Reviews from '../../components/Reviews/Reviews';
import ContactUs from '../../components/ContactUs/ContactUs';

const Home = () => {
    return (
        <>
            <Banner banner={banner}/>
            <Speedscan speedscan={speedscan}/>
            <Reviews reviews={reviews}/>
            <ContactUs {...contactus} />
        </>
    )
}

export default Home;