import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import '../ContactUs/ContactUs.css';
import emailjs from '@emailjs/browser';

const ContactUs = ({ cotactsectiontitle, contactbgimg, contactdetailparagraph, contactlist1, contactlist2, contactlist3, contactformtitle, submitbtntext }) => {
    const [formData, setFormData] = useState({
        from_name: '',
        from_number: '',
        from_email: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        from_name: '',
        from_number: '',
        from_email: '',
        message: ''
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the input is from_number, ensure it is limited to 10 digits
        if (name === 'from_number' && (value.length > 10 || !/^\d*$/.test(value))) {
            return;
        }

        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
        setSuccessMessage('');
    };

    const validate = () => {
        let valid = true;
        const newErrors = {};

        if (!formData.from_name.trim()) {
            newErrors.from_name = 'Name is required';
            valid = false;
        }

        if (!formData.from_number.trim()) {
            newErrors.from_number = 'Phone number is required';
            valid = false;
        } else if (formData.from_number.length !== 10) {
            newErrors.from_number = 'Phone number must be exactly 10 digits';
            valid = false;
        }

        if (!formData.from_email.trim()) {
            newErrors.from_email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.from_email)) {
            newErrors.from_email = 'Email address is invalid';
            valid = false;
        }

        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (validate()) {
            emailjs.sendForm('service_zz7ocb4', 'template_sy5nyun', e.target, 'qcrIe6YiTTuCRjnJX')
                .then((result) => {
                    console.log('Email successfully sent:', result.text);
                    setSuccessMessage('Your message has been sent successfully!');
                    setFormData({
                        from_name: '',
                        from_number: '',
                        from_email: '',
                        message: ''
                    });
                }, (error) => {
                    console.log('Failed to send email:', error.text);
                    alert('There was an error sending your message, please try again later.');
                });
        }
    };

    return (
        <>
            <div className='contact-us' id='contact' style={contactbgimg}>
                <Container>
                    <div className='contact-content'>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <div className='contact-detail'>
                                    <div className='contact-info'>
                                        <div className='section-title'>
                                            <h2>{cotactsectiontitle}</h2>
                                        </div>
                                        <div className='common-detail'>
                                            <p>{contactdetailparagraph}</p>
                                        </div>
                                    </div>
                                    <div className='contact-list'>
                                        <ul>
                                            <li>{contactlist1}</li>
                                            <li>{contactlist2}</li>
                                            <li>{contactlist3}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <div className='contact-form-part'>
                                    <div className='small-title'>
                                        <h3>{contactformtitle}</h3>
                                    </div>
                                    <div className='contact-form-block'>
                                        <Form className='form' onSubmit={sendEmail}>
                                            <Row>
                                                <Col xs={12}>
                                                    <div className='form-block'>
                                                        <Form.Group className='form-group' controlId="formGroupText">
                                                            <Form.Control name='from_name' type="text" placeholder=" " value={formData.from_name} onChange={handleChange} />
                                                            <Form.Label>Your Name</Form.Label>
                                                        </Form.Group>
                                                        {errors.from_name && <p className='error'>{errors.from_name}</p>}
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                                    <div className='form-block'>
                                                        <Form.Group className='form-group' controlId="formGroupNumber">
                                                            <Form.Control name='from_number' type="text" placeholder=" " value={formData.from_number} onChange={handleChange} />
                                                            <Form.Label>Your Phone</Form.Label>
                                                        </Form.Group>
                                                        {errors.from_number && <p className='error'>{errors.from_number}</p>}
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                                    <div className='form-block'>
                                                        <Form.Group className='form-group' controlId="formGroupEmail">
                                                            <Form.Control name='from_email' type="email" placeholder=" " value={formData.from_email} onChange={handleChange} />
                                                            <Form.Label>Your Email</Form.Label>
                                                        </Form.Group>
                                                        {errors.from_email && <p className='error'>{errors.from_email}</p>}
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className='form-block'>
                                                        <Form.Group className='form-group textarea' controlId="formGroupTextarea">
                                                            <Form.Control name='message' as="textarea" placeholder=" " value={formData.message} onChange={handleChange} />
                                                            <Form.Label>Your Message</Form.Label>
                                                        </Form.Group>
                                                        {errors.message && <p className='error'>{errors.message}</p>}
                                                    </div>
                                                </Col>
                                            </Row>
                                            {successMessage && <p className='success'>{successMessage}</p>}
                                            <div className='submit-btn'>
                                                <Button type="submit" className='btn btn-gradient'>
                                                    {submitbtntext}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ContactUs;
