import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../Footer/Footer.css';
import { Link } from 'react-router-dom'; 
import { Link as ScrollLink } from 'react-scroll';


const Footer = ({ logo, alt, footerinfotitle, footerinfodetail, footerlinktitle1, footerlink1, footerlink2, footerlink3, footerlink4, footerlink5, footerlink6, footerlink7, footerlink8, footerlink9, footerlink10, footerlink11, footerlinktitle2, footercopyright }) => {

    const [activeLink, setActiveLink] = useState('');

    const handleSetActive = (link) => {
        setActiveLink(link);
    };
    return (
        <>
            <footer className='footer'>
                <div className='footer-top'>
                    <Container>
                        <div className='footer-content'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                    <div className='footer-logo-part'>
                                        <div className='footer-logo'>
                                            <Link to="/">
                                                <img src={logo} alt={alt} className='img-fluid' />
                                            </Link>
                                        </div>
                                        <div className='footer-info'>
                                            <div className='footer-info-title'>
                                                <h4>{footerinfotitle}</h4>
                                            </div>
                                            <div className='common-detail'>
                                                <p>{footerinfodetail}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                    <div className='footer-link-block'>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <div className='footer-link-part'>
                                                    <div className='footer-link-title'>
                                                        <h4>{footerlinktitle1}</h4>
                                                    </div>
                                                    <div className='footer-link-list'>
                                                        <ul>
                                                            <li><ScrollLink to="speedscan-row-0" onSetActive={() => handleSetActive('speedscan-row-0')} smooth={true} duration={500} offset={-50} spy={true} activeClass='active'>{footerlink1}</ScrollLink></li>
                                                            <li><ScrollLink to="speedscan-row-1" smooth={true} duration={500} offset={-50} spy={true} activeClass='active'>{footerlink2}</ScrollLink></li>
                                                            <li><ScrollLink to="speedscan-row-2" smooth={true} duration={500} offset={-50} spy={true} activeClass='active'>{footerlink3}</ScrollLink></li>
                                                            <li><ScrollLink to="speedscan-row-3" smooth={true} duration={500} offset={-50} spy={true} activeClass='active'>{footerlink4}</ScrollLink></li>
                                                            {/* <li><ScrollLink to='/'>{footerlink5}</ScrollLink></li> */}
                                                            <li><ScrollLink to="contact" smooth={true} duration={500} offset={-50} spy={true} activeClass='active'>{footerlink6}</ScrollLink></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <div className='footer-link-part'>
                                                    <div className='footer-link-title'>
                                                        <h4>{footerlinktitle2}</h4>
                                                    </div>
                                                    <div className='footer-link-list'>
                                                        <ul>
                                                            <li><ScrollLink to="/">{footerlink7}</ScrollLink></li>
                                                            <li><ScrollLink to="/">{footerlink8}</ScrollLink></li>
                                                            <li><ScrollLink to="/">{footerlink9}</ScrollLink></li>
                                                            <li><ScrollLink to="/">{footerlink10}</ScrollLink></li>
                                                            <li><ScrollLink to="/">{footerlink11}</ScrollLink></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className='footer-bottom'>
                    <Container>
                        <div className='footer-bottom-content'>
                            <div className='footer-copyright'>
                                <p>{footercopyright}</p>
                            </div>
                        </div>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default Footer
