import './App.css';
import { footer } from './components/Data';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import './fonts/fonts.css';
import Home from './pages/Home/Home';

function App() {
  return (
    <>
      <Header/>
      <Home/>
      <Footer {...footer}/>
    </>
  );
}

export default App;
