import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import logo from '../../images/logo.png';
import '../Header/Header.css';

const Header = () => {
    const [expanded, setExpanded] = useState(false);
    const [activeLink, setActiveLink] = useState('');

    const handleNavClick = () => {
        setExpanded(false); // Close the Navbar when a Nav.Link is clicked
    };

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const handleSetActive = (link) => {
        setActiveLink(link);
    };

    return (
        <>
            <header className='header'>
                <Navbar expand="lg" expanded={expanded}>
                    <Container>
                        <Navbar.Brand as={Link} to="#">
                            <img src={logo} alt='logo' className='img-fluid' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
                        <Navbar.Collapse id="basic-navbar-nav" >
                            <Nav className="m-auto">
                                <Nav.Link
                                    as={ScrollLink}
                                    to="speedscan-row-0"
                                    smooth={true}
                                    duration={500}
                                    offset={-50}
                                    onClick={handleNavClick}
                                    spy={true}
                                    onSetActive={() => handleSetActive('speedscan-row-0')}
                                    activeClass='active'
                                >
                                    Speedscan
                                </Nav.Link>
                                <Nav.Link
                                    as={ScrollLink}
                                    to="speedscan-row-1"
                                    smooth={true}
                                    duration={500}
                                    offset={-50}
                                    onClick={handleNavClick}
                                    spy={true}
                                    onSetActive={() => handleSetActive('speedscan-row-1')}
                                    activeClass='active'
                                >
                                    vCISO
                                </Nav.Link>
                                <Nav.Link
                                    as={ScrollLink}
                                    to="speedscan-row-2"
                                    smooth={true}
                                    duration={500}
                                    offset={-50}
                                    onClick={handleNavClick}
                                    spy={true}
                                    onSetActive={() => handleSetActive('speedscan-row-2')}
                                    activeClass='active'
                                >
                                    Cloud Security
                                </Nav.Link>
                                <Nav.Link
                                    as={ScrollLink}
                                    to="speedscan-row-3"
                                    smooth={true}
                                    duration={500}
                                    offset={-50}
                                    onClick={handleNavClick}
                                    spy={true}
                                    onSetActive={() => handleSetActive('speedscan-row-3')}
                                    activeClass='active'
                                >
                                    Compliance
                                </Nav.Link>
                                {/* <Nav.Link as={Link} to="#" onClick={handleNavClick} > Blog </Nav.Link> */}
                            </Nav>
                            <div className='header-btn'>
                                <ScrollLink
                                    to='contact'
                                    smooth={true}
                                    duration={500}
                                    offset={-50}
                                    onClick={handleNavClick}
                                    spy={true}
                                    onSetActive={() => handleSetActive('contact')}
                                    className='btn btn-gradient'
                                    activeClass='active'
                                >
                                    Contact Us
                                </ScrollLink>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
}

export default Header;
